import { connect } from 'react-redux';
import Component from './component';

import {
    list as listMedicalQuestions,
} from 'Redux/modules/trainer/medicalQuestions/actions';
import {
    create as createMedicalCard,
} from 'Redux/modules/trainer/medicalCards/actions';
import {
    single as singleUser, 
} from 'Redux/modules/trainer/users/actions';

export default connect(
    state => ({
        medicalQuestions: state.trainerMedicalQuestions.list,
    }),
    dispatch => ({
        actions: {
            listMedicalQuestions: dispatch(listMedicalQuestions),
            createMedicalCard: dispatch(createMedicalCard),
            singleUser: dispatch(singleUser),
        },
    })
)(Component);