import { reduxRequest as request } from 'Services/Api';
import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_AUTH_MEDICAL_QUESTIONS,
        params,
    }));
};