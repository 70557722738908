import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        position: relative;
        width: 100%;

        .input-internal-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            .component-button {
                margin: 0 1em;

                &:first-of-type {
                    margin-left: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    `;
