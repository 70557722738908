export function dedupeObjects(array, key) {
    return array.filter((item, index, self) =>
        index === self.findIndex((t) => (
            t[key] === item[key]
        ))
    );
}

export function dedupe(array) {
    return array.filter((item, pos) => {
        return array.indexOf(item) === pos;
    });
}

export function chunkArray(array, length) {
    return array.reduce(
        (a, b, index, currentArray) => !(index % length)
            ? a.concat([currentArray.slice(index, index + length)])
            : a, []
    );
}

export function sortByKey(array, key) {
    return array.sort(function (a, b) {
        var x = a[key]; var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}

export function mode(arr) {
    return arr.sort((a, b) =>
        arr.filter(v => v === a).length
        - arr.filter(v => v === b).length
    ).pop();
}
