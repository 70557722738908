import { reduxRequest as request } from 'Services/Api';
import * as types from './types';
import * as endpoints from 'Consts/api';
import { withVariables } from 'Utils/string';

export const create = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: endpoints.ENDPOINT_TRAINER_MEDICAL_CARDS,
        params,
        requestParams: {
            answers: params.answers || [],
            userId: params.userId,
            weight: params.weight,
            height: params.height,
            birthdate: params.birthdate,
            withUserData: params.withUserData,
        },
    }));
};

export const current = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CURRENT,
        method: 'GET',
        path: endpoints.ENDPOINT_TRAINER_MEDICAL_CARDS_LATEST,
        params,
        requestParams: {
            userId: params.userId,
        },
    }));
};

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_TRAINER_MEDICAL_CARDS,
        params,
        requestParams: {
            userId: params.userId,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CURRENT,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_TRAINER_MEDICAL_CARD, params),
        params,
    }));
};