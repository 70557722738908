import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import Button from 'Components/layout/Button';
import ExternalWrapper from 'Components/forms/InputExternalWrapper';
import InternalWrapper from 'Components/forms/InputInternalWrapper';
import ErrorMessage from 'Components/forms/ErrorMessage';
import InputHelper from 'Components/forms/InputHelper';
import InputLabel from 'Components/forms/InputLabel';

export default class FormButtonsGroupComponent extends React.Component {
    static displayName = 'FormButtonsGroupComponent';

    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.any,
        onChange: PropTypes.func,
        onFormChange: PropTypes.func,
        label: PropTypes.string,
        helper: PropTypes.string,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        className: PropTypes.string,
        style: PropTypes.oneOf([
            'primary',
            'faPrimary',
        ]),
        size: PropTypes.oneOf([
            'small',
            'medium',
            'large',
        ]),
        errorMessage: PropTypes.string,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.any,
                label: PropTypes.string.isRequired,
            })
        ),
    };

    static defaultProps = {
        value: '',
        onChange: null,
        onFormChange: null,
        label: '',
        helper: '',
        disabled: false,
        required: false,
        className: '',
        style: 'primary',
        size: 'medium',
        errorMessage: '',
        options: [],
    }

    state = {
        defaultOptions: [],
    };

    onChange = value => {
        const { name, onChange, onFormChange } = this.props;

        const data = {
            name,
            value,
        };

        if (onChange) {
            onChange(data);
        }

        if (onFormChange) {
            onFormChange(data);
        }

        return data;
    }

    render() {
        const { className, value, label, helper, errorMessage, disabled, options, style, size } = this.props;

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames(
                    'form-element',
                    'buttons-group',
                    className,
                    `style-${style}`,
                    `size-${size}`
                )}
            >
                <ExternalWrapper>
                    {label && <InputLabel label={label} floating={true} style={style} />}
                    <InternalWrapper style={style}>
                        {options.map(({ label, value }) => (
                            <Button
                                key={label + value}
                                style={style}
                                size={size}
                                onClick={() => this.onChange(value)}
                                disabled={disabled}
                            >
                                {label}
                            </Button>
                        ))}
                    </InternalWrapper>
                    {helper && <InputHelper helper={helper} />}
                    {errorMessage && <ErrorMessage message={errorMessage} />}
                </ExternalWrapper>
            </StyledComponent>
        );
    }
}
